/* eslint-disable */

import { createWebHistory, createRouter } from "vue-router";
import Store from "../store";
import ErrorComponent from "@/components/Error";
import DataLayerService from "@/services/data-layer.service";

var Page = [
  "DealCreate",
  "SiteCreate",
  "Performance",
  "VisitRatio",
  "MyAmbassadors",
  "MyFriends",
];
async function requireAuth(to, from, next) {
  if (
    Store.state.cognitoInfo &&
    Object.keys(Store.state.cognitoInfo).length === 0
  ) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
    return;
  }

  // Check if routedataInfo is empty and fetch if necessary
  if (
    !Store ||
    !Store.state ||
    !Store.state.routedataInfo ||
    Object.keys(Store.state.routedataInfo).length === 0
  ) {
    const result = await DataLayerService.get("/home/GetActiveRole");
    if (result && result.length > 0) {
      await Store.commit("setRoutedataInfo", result);
    } else {
      next({ name: "Logout" });
      return;
    }
  }

  if (
    !Store ||
    !Store.state ||
    !Store.state.menudataInfo ||
    Object.keys(Store.state.menudataInfo).length === 0
  ) {
    await DataLayerService.get("/home/GetMenuData").then(async (result) => {
      if (result && Object.keys(result).length > 0) {
        await Store.commit("setMenudataInfo", result);
      } else {
        next({ name: "Logout" });
        return;
      }
    });
  }

  let checkRoute;
  if (to && to.name) {
    checkRoute = Store.state.routedataInfo?.find(
      (x) => x.name.toLowerCase() === to.name.toLowerCase()
    );
  }
  if (!checkRoute) {
    next({ name: "notfound" });
    return;
  }

  const _userInfo = Store.state.cognitoInfo;
  if (
    _userInfo &&
    (_userInfo.companyid == null ||
      _userInfo.companyid == undefined ||
      _userInfo.companyid == "")
  ) {
    await DataLayerService.get("/user/getuserinfo").then(async (userRes) => {
      if (userRes) {
        if (
          userRes.companyId == "" ||
          userRes.companyId == null ||
          userRes.companyId == undefined
        ) {
          this.$router.push({ name: "Logout" });
          return;
        }
        var mainres = {};
        mainres.email = _userInfo.email;
        mainres.username = _userInfo.username;
        mainres.displayname = userRes.displayName;
        mainres.companyid = userRes.companyId;
        mainres.companyname = userRes.companyName;
        mainres.companylist = userRes.companyIdList;
        mainres.role = userRes.role;
        mainres.token = Store.state.cognitoInfo.token;
        await Store.commit("setCognitoInfo", mainres);
        return mainres;
      } else {
        next({ name: "Logout" });
        return;
      }
    });
  }

  if (
    [
      "DealCreate",
      "SiteCreate",
      "Performance",
      "VisitRatio",
      "MyAmbassadors",
      "MyFriends",
    ].includes(to.name) &&
    _userInfo.companyid === "_all" &&
    from.name === null
  ) {
    next({ name: "Home" });
    return;
  }

  next();
}

const routes = [
  {
    path: Store.getters.GetRoutePath("home"),
    name: "Home",
    //component: () => import("../views/Home.vue"),
    component: () => import("../views/Dashboard/Dashboard.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("newsitelist"),
    name: "NewSiteList",
    component: () => import("../views/NewSite/Site.vue"),
  },
  {
    path: Store.getters.GetRoutePath("elevatelist"),
    name: "elevatelist",
    component: () => import("../views/NewSite/Elevatesites.vue"),
  },
  {
    path: Store.getters.GetRoutePath("elevateedit"),
    name: "elevateedit",
    component: () => import("../views/Voyadoelevate/elevateindex.vue"),
  },
  {
    path: Store.getters.GetRoutePath("acceleratorsite"),
    name: "acceleratorsite",
    component: () => import("../views/NewSite/AcceleratorSites.vue"),
  },
  {
    path: Store.getters.GetRoutePath("acceleratoredit"),
    name: "acceleratoredit",
    component: () => import("../views/Accelerator/index.vue"),
  },
  {
    path: Store.getters.GetRoutePath("leadadslist"),
    name: "leadadslist",
    component: () => import("../views/NewSite/LeadadsSite.vue"),
  },
  {
    path: Store.getters.GetRoutePath("newsitecreate"),
    name: "NewSiteCreate",
    component: () => import("../views/NewSite/SiteCreate.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("newsiteedit"),
    name: "NewSiteEdit",
    component: () => import("../views/NewSite/SiteCreate.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("newsitecopy"),
    name: "NewSiteCopy",
    component: () => import("../views/NewSite/SiteCreate.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("sitelist"),
    name: "SiteList",
    component: () => import("../views/Site/Site.vue"),
  },
  {
    path: Store.getters.GetRoutePath("sitecreate"),
    name: "SiteCreate",
    component: () => import("../views/Site/Create.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("siteedit"),
    name: "SiteEdit",
    component: () => import("../views/Site/Create.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: Store.getters.GetRoutePath("sitecopy"),
    name: "SiteCopy",
    component: () => import("../views/Site/Create.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("siteChangelog"),
    name: "SiteChangelog",
    component: () => import("../views/Reports/SiteChangelog.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("companyChangelog"),
    name: "CompanyChangelog",
    component: () => import("../views/Reports/CompanyChangelog.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("deallist"),
    name: "DealList",
    component: () => import("../views/Deal/List.vue"),
    beforeEnter: requireAuth,
  },
  // {
  //   path: Store.getters.GetRoutePath("dealcreate"),
  //   name: "DealCreate",
  //   component: () => import("../views/Deal/Create.vue"),
  //   beforeEnter: requireAuth,
  // },
  {
    path: Store.getters.GetRoutePath("newdealcreate"),
    name: "NewDealCreate",
    component: () => import("../views/Deal/Create-new.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("dealedit"),
    name: "DealEdit",
    component: () => import("../views/Deal/Edit.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("neweditdeal"),
    name: "DealEditNew",
    component: () => import("../views/Deal/Edit-new.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("performance"),
    name: "Performance",
    component: () => import("../views/Reports/Performance.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("myambassadors"),
    name: "Ambassadors",
    component: () => import("../views/Reports/MyAmbassadors.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("myfriends"),
    name: "Friends",
    component: () => import("../views/Reports/MyFriends.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: Store.getters.GetRoutePath("relations"),
    name: "Relations",
    component: () => import("../views/Reports/Relations.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("companylist"),
    name: "CompanyList",
    component: () => import("../views/Company/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("companycreate"),
    name: "CompanyCreate",
    component: () => import("../views/Company/Create.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("companyedit"),
    name: "CompanyEdit",
    component: () => import("../views/Company/Create.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("changelog"),
    name: "Changelog",
    component: () => import("../views/Reports/Changelog.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("userlist"),
    name: "UserList",
    component: () => import("../views/User/User.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("usercreate"),
    name: "UserCreate",
    component: () => import("../views/User/CreateUser.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("useredit"),
    name: "UserEdit",
    component: () => import("../views/User/CreateUser.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("updatetranslation"),
    name: "Translation",
    component: () => import("../views/Translation/Translation.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("contactlist"),
    name: "FailedContact",
    component: () => import("../views/Contact/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("errorsummary"),
    name: "Error",
    component: () => import("../views/Contact/ErrorSummary.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("modifydeal"),
    name: "ModifyDeal",
    component: () => import("../views/DealList/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("managedeallist"),
    name: "ManageDealList",
    component: () => import("../views/ManageDeals/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("editdeal"),
    name: "EditDeal",
    component: () => import("../views/UpdateDeal/Update.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("managedeallistnew"),
    name: "ManageDealListNew",
    component: () => import("../views/ManageDealsNew/List.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("newonsitemessage"),
    name: "NewOnsiteMessage",
    component: () => import("../views/UpdateDealNew/Update.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("copydeal"),
    name: "copydeal",
    component: () => import("../views/ManageDealUpdate/index.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("copydealnew"),
    name: "copydealnew",
    component: () => import("../views/UpdateDealNew/Update.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("dealsearch"),
    name: "DealSearch",
    component: () => import("../views/Reports/DealSearch.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("notfound"),
    name: "notfound",
    component: () => import("../views/Pages/Notfound.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("signupwizard"),
    name: "Signupwizard",
    component: () => import("../views/signupwizard/signupwizard.vue"),
    beforeEnter: requireAuth,
  },
  // {
  //   path: Store.getters.GetRoutePath("managedealupdate"),
  //   name: "ManageDealUpdate",
  //   component: () => import("../views/ManageDealUpdate/index.vue"),
  //   beforeEnter: requireAuth,
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../views/forgotpassword.vue"),
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/resetpassword.vue"),
  },
  {
    path: "/changepassword",
    name: "ChangePassword",
    component: () => import("../views/changepassword.vue"),
  },

  {
    path: Store.getters.GetRoutePath("managedealupdateCreate"),
    name: "ManageDealUpdateCreate",
    component: () => import("../views/ManageDealUpdate/index.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: Store.getters.GetRoutePath("editdealnew"),
    name: "EditDealNew",
    component: () => import("../views/UpdateDealNew/Update.vue"),
    beforeEnter: requireAuth,
  },

  {
    path: Store.getters.GetRoutePath("managedealupdateById"),
    name: "ManageDealUpdateById",
    component: () => import("../views/ManageDealUpdate/index.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("contentgenerator"),
    name: "contentgenerator",
    component: () => import("../views/ContentGenerator/contentgenerator.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("leadads"),
    name: "leadads",
    component: () => import("../views/LeadAds/index.vue"),
    beforeEnter: requireAuth,
  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   beforeEnter() {
  //     // console.log(auth.auth.getSession(), 'auth.auth.getSession()')
  //     auth.auth.getSession();
  //   },
  // },
  // {
  //   path: "/login/oauth2/code/cognito",
  //   name: "LoginCognito",
  //   beforeEnter() {
  //     var currUrl = window.location.href;
  //     auth.auth.parseCognitoWebResponse(currUrl);
  //   },
  // },
  {
    path: "/error",
    component: ErrorComponent,
    beforeEnter: requireAuth,
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      Store.state.loader = 0;
      Store.commit("resetCognitoInfo");
      Store.commit("resetMenudataInfo");
      Store.commit("resetRoutedataInfo");
      Store.commit("resetDealFilter");
      Store.commit("resetDashboardInfo");
      Store.commit("resetDashbboardFilter");
      Store.commit("resetLead");

      const queryParams = from.query;
      next({ path: "/login", query: queryParams });
    },
  },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   beforeEnter(to, from, next) {
  //     // console.log(auth,876);
  //     auth.logout();
  //     next("/");
  //   },
  // },

  {
    path: "/:catchAll(.*)",
    redirect: "/login",
    component: () => import("../views/login.vue"),
  },
  {
    path: Store.getters.GetRoutePath("newdealwizard"),
    name: "Newdealwizard",
    component: () => import("../views/NewDealWizard/index.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("overview"),
    name: "VoyadoDashboard",
    component: () => import("../views/VoyadoDashboard/voyadodashboard.vue"),
    beforeEnter: requireAuth,
  },
  {
    path: Store.getters.GetRoutePath("acceleratordashboard"),
    name: "acceleratordashboard",
    component: () => import("../views/AcceleratorDashboard/Accelerator.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  // base:"/",
  routes,
});

router.beforeEach((to, from, next) => {
  Store.commit("setActiveRoute", to.meta.module);
  next();
});

export default router;
